import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CardHeader,
  CardContent,
  MenuItem,
  Stack,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import {
  People as PeopleIcon,
  Payments as PaymentsIcon,
  Payment as PaymentIcon
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { MuiCard, MuiTextField, SummaryCardChart, QueryError } from 'components'
import {
  ALL,
  getClientsSummaryFilterPath,
  getLoansSummaryFilterPath,
  getPaymentsSummaryFilterPath,
  getSummaryAggregateWhere
} from 'utils/chart'
import { formatCurrency } from 'utils/currency'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'
import { useGetChartsSummaryDataQuery } from 'api/generated'
import { LOAN_PAYMENTS_FILTERS_ENUM } from 'utils/loanFilters'
import { PAYMENTS_FILTERS_ENUM } from 'utils/paymentFilters'

const SummaryCharts: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [locationId, setLocationId] = useState(ALL)
  const { data, loading, error, refetch } = useGetChartsSummaryDataQuery({
    variables: getSummaryAggregateWhere(locationId)
  })
  const locations = dictionariesQuery?.data?.locations
  const clientsAggregate = data?.clientsAggregate?.aggregate
  const loansAggregate = data?.loansAggregate?.aggregate
  const upToDateLoansAggregate = data?.upToDateLoansAggregate?.aggregate
  const todayLoansAggregate = data?.todayLoansAggregate?.aggregate
  const lateLoansAggregate = data?.lateLoansAggregate?.aggregate
  const paymentsAggregate = data?.paymentsAggregate?.aggregate
  const todayPaymentsAggregate = data?.todayPaymentsAggregate?.aggregate
  const weeklyPaymentsAggregate = data?.weeklyPaymentsAggregate?.aggregate
  const monthlyPaymentsAggregate = data?.monthlyPaymentsAggregate?.aggregate

  const handleChangeLocationId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocationId(ev.target.value)
    },
    []
  )

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  const handleOnLoanNavigate = useCallback(
    (loanPaymentFilter?: LOAN_PAYMENTS_FILTERS_ENUM) => {
      const selectedLocation = locations?.find(
        location => location?.id === locationId
      )

      const path = getLoansSummaryFilterPath(
        selectedLocation?.name,
        loanPaymentFilter
      )

      navigate(path)
    },
    [locations, locationId, navigate]
  )

  const handleOnPaymentNavigate = useCallback(
    (paymentFilter?: PAYMENTS_FILTERS_ENUM) => {
      const selectedLocation = locations?.find(
        location => location?.id === locationId
      )

      const path = getPaymentsSummaryFilterPath(
        selectedLocation?.name,
        paymentFilter
      )

      navigate(path)
    },
    [locations, locationId, navigate]
  )

  const handleOnClientNavigate = useCallback(() => {
    const selectedLocation = locations?.find(
      location => location?.id === locationId
    )

    const path = getClientsSummaryFilterPath(selectedLocation?.name)

    navigate(path)
  }, [locations, locationId, navigate])

  return (
    <MuiCard>
      <CardHeader title={t('summary')} />

      <CardContent>
        <MuiTextField
          select
          label={t('location')}
          size='small'
          onChange={handleChangeLocationId}
          value={locationId}
        >
          <MenuItem value={ALL}>{t(ALL)}</MenuItem>
          {locations?.map(location => {
            return (
              <MenuItem key={location?.id} value={location?.id}>
                {location?.name}
              </MenuItem>
            )
          })}
        </MuiTextField>

        {error ? (
          <QueryError
            error={error}
            loading={loading}
            refetch={handleRefetchQuery}
          />
        ) : (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            marginTop={1}
            overflow={{ sm: 'auto' }}
            whiteSpace={{ sm: 'nowrap' }}
            py={1}
            height={{ sm: 164 }}
          >
            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'primary.main' }
                }}
                cardHeaderProps={{
                  title: t('loans'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalLoans')}>
                        <Typography variant='caption'>
                          {loansAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton onClick={() => handleOnLoanNavigate()}>
                        <PaymentsIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount: loansAggregate?.sum?.balance
                })}
                valueTooltipTitle={t('capital')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'success.light' }
                }}
                cardHeaderProps={{
                  title: t('upToDateLoans'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalLoans')}>
                        <Typography variant='caption'>
                          {upToDateLoansAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton
                        onClick={() =>
                          handleOnLoanNavigate(
                            LOAN_PAYMENTS_FILTERS_ENUM.UP_TO_DATE
                          )
                        }
                      >
                        <PaymentsIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount: upToDateLoansAggregate?.sum?.balance
                })}
                valueTooltipTitle={t('capital')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'info.light' }
                }}
                cardHeaderProps={{
                  title: t('todayLoans'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalLoans')}>
                        <Typography variant='caption'>
                          {todayLoansAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton
                        onClick={() =>
                          handleOnLoanNavigate(LOAN_PAYMENTS_FILTERS_ENUM.TODAY)
                        }
                      >
                        <PaymentsIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount: todayLoansAggregate?.sum?.balance
                })}
                valueTooltipTitle={t('capital')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'error.light' }
                }}
                cardHeaderProps={{
                  title: t('lateLoans'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalLoans')}>
                        <Typography variant='caption'>
                          {lateLoansAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton
                        onClick={() =>
                          handleOnLoanNavigate(LOAN_PAYMENTS_FILTERS_ENUM.LATE)
                        }
                      >
                        <PaymentsIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount: lateLoansAggregate?.sum?.balance
                })}
                valueTooltipTitle={t('capital')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'primary.main' }
                }}
                cardHeaderProps={{
                  title: t('payments'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalPayments')}>
                        <Typography variant='caption'>
                          {paymentsAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton onClick={() => handleOnPaymentNavigate()}>
                        <PaymentIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount:
                    (paymentsAggregate?.sum?.amount || 0) +
                    (paymentsAggregate?.sum?.penalty_amount || 0)
                })}
                valueTooltipTitle={t('totalAmount')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'info.light' }
                }}
                cardHeaderProps={{
                  title: t('todayPayments'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalPayments')}>
                        <Typography variant='caption'>
                          {todayPaymentsAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton
                        onClick={() =>
                          handleOnPaymentNavigate(PAYMENTS_FILTERS_ENUM.DAILY)
                        }
                      >
                        <PaymentIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount:
                    (todayPaymentsAggregate?.sum?.amount || 0) +
                    (todayPaymentsAggregate?.sum?.penalty_amount || 0)
                })}
                valueTooltipTitle={t('totalAmount')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'info.main' }
                }}
                cardHeaderProps={{
                  title: t('weeklyPayments'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalPayments')}>
                        <Typography variant='caption'>
                          {weeklyPaymentsAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton
                        onClick={() =>
                          handleOnPaymentNavigate(PAYMENTS_FILTERS_ENUM.WEEKLY)
                        }
                      >
                        <PaymentIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount:
                    (weeklyPaymentsAggregate?.sum?.amount || 0) +
                    (weeklyPaymentsAggregate?.sum?.penalty_amount || 0)
                })}
                valueTooltipTitle={t('totalAmount')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1, backgroundColor: 'success.light' }
                }}
                cardHeaderProps={{
                  title: t('monthlyPayments'),
                  subheader: (
                    <Stack direction='row' spacing={1}>
                      <Tooltip title={t('totalPayments')}>
                        <Typography variant='caption'>
                          {monthlyPaymentsAggregate?.totalCount}
                        </Typography>
                      </Tooltip>
                    </Stack>
                  ),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton
                        onClick={() =>
                          handleOnPaymentNavigate(PAYMENTS_FILTERS_ENUM.MONTHLY)
                        }
                      >
                        <PaymentIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={formatCurrency({
                  amount:
                    (monthlyPaymentsAggregate?.sum?.amount || 0) +
                    (monthlyPaymentsAggregate?.sum?.penalty_amount || 0)
                })}
                valueTooltipTitle={t('totalAmount')}
                loading={loading}
              />
            </Stack>

            <Stack minWidth={{ sm: 275 }} height={145}>
              <SummaryCardChart
                cardProps={{
                  sx: { flex: 1 }
                }}
                cardHeaderProps={{
                  title: t('clients'),
                  action: (
                    <Tooltip title={t('showInDetail')}>
                      <IconButton onClick={() => handleOnClientNavigate()}>
                        <PeopleIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                value={clientsAggregate?.totalCount || 0}
                valueTooltipTitle={t('totalClients')}
                loading={loading}
              />
            </Stack>
          </Stack>
        )}
      </CardContent>
    </MuiCard>
  )
}

export default SummaryCharts
