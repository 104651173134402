import { GridValueFormatter } from '@mui/x-data-grid-pro'

export const truncateId = (id: string, length = 8): string => {
  if (!id) {
    return ''
  }

  return `${id?.slice(0, length)}...${id?.slice(id?.length - length)}`
}

export const toNumber = (value?: string): number => Number(value || '0')

export const toString = (value?: number | null): string =>
  typeof value === 'number' ? value?.toString() : '0'

export const dataGridPercentageFormatter: GridValueFormatter = (
  value: number
) => {
  return typeof value === 'number' ? `${value} %` : ''
}

export default {
  truncateId,
  toNumber,
  toString,
  dataGridPercentageFormatter
}
