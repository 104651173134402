import React from 'react'
import { AppBar, Toolbar, Typography, Box, useTheme } from '@mui/material'

import MuiLink from '../../MuiLink'

const Header: React.FC = () => {
  const {
    palette: {
      common: { white }
    }
  } = useTheme()

  return (
    <>
      <AppBar position='fixed'>
        <Toolbar>
          <Box
            display='flex'
            flex={1}
            justifyContent='space-between'
            alignItems='center'
          >
            <MuiLink to={`/`}>
              <Typography component='h1' variant='h6'>
                <Box color={white}>Credisaas</Box>
              </Typography>
            </MuiLink>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

export default Header
