import { GridValueFormatter } from '@mui/x-data-grid-pro'
import { Currency_Enum, LoanFragmentFragment } from 'api/generated'

export type CurrencyMetadataType = {
  value: Currency_Enum
  symbol: string
}

export const getCurrencyMetadata = (
  currency?: Currency_Enum
): CurrencyMetadataType | null => {
  switch (currency) {
    case Currency_Enum.Crc:
      return {
        value: currency,
        symbol: '₡'
      }

    case Currency_Enum.Usd:
      return {
        value: currency,
        symbol: '$'
      }

    default:
      return null
  }
}

export type FormatCurrencyProps = {
  amount?: number | null
  locales?: string | string[]
  options?: Intl.NumberFormatOptions
  currency?: string
}

export const formatCurrency = ({
  amount = 0,
  locales = 'en-US',
  currency = 'CRC',
  options = {
    style: 'currency',
    currency: 'CRC',
    currencyDisplay: 'narrowSymbol'
  }
}: FormatCurrencyProps): string => {
  return new Intl.NumberFormat(locales, {
    ...options,
    ...(currency ? { currency } : {})
  }).format(amount || 0)
}

export const dataGridCurrencyLoanFormatter: GridValueFormatter<
  LoanFragmentFragment
> = (value: number, row: LoanFragmentFragment) => {
  return formatCurrency({
    amount: value,
    currency: row?.currency
  })
}

export default {
  getCurrencyMetadata,
  formatCurrency,
  dataGridCurrencyLoanFormatter
}
