import * as yup from 'yup'

export type IdSchemaType = {
  id: string
}

export type EmailSchemaType = {
  email: string
}

export type AutoCompleteSchemaType = {
  value: string
  autoCompleteValue?: string
}

export const string = yup.string()
export const object = yup.object()
export const number = yup.number()
export const array = yup.array()
export const date = yup.date()
export const email = string.email('invalidEmail')
export const uuid = string.uuid('requiredField')
export const stringRequired = string.required('requiredField')
export const stringNullable = string.nullable()
export const numberRequired = number.required('requiredField')
export const numberNullable = number.nullable()
export const emailRequired = email.required('requiredField')
export const emailNullable = email.nullable()
export const uuidRequired = uuid.required('requiredField')
export const uuidNullable = uuid.nullable()
export const dateRequired = date.required('requiredField')
export const dateNullable = date.nullable()

export const phoneNumberSchema = object.shape({
  id: stringNullable,
  value: stringRequired,
  type: stringRequired,
  description: stringNullable
})

export const geolocationSchema = object.shape({
  lat: numberRequired,
  lng: numberRequired
})

export const addressSchema = object.shape({
  id: stringNullable,
  value: stringRequired,
  type: stringRequired,
  description: stringNullable,
  geolocation: geolocationSchema
})

export const fileValueSchema = object.shape({
  value: stringRequired,
  loading: stringRequired,
  error: stringNullable
})

export const fileSchema = object.shape({
  id: stringNullable,
  value: fileValueSchema,
  type: stringRequired,
  description: stringNullable
})

export const idAutoCompleteSchema = object.shape({
  value: uuidRequired,
  autoCompleteValue: stringNullable
})

export const idSchema = object.shape({
  id: uuidRequired
})

export const emailSchema = object.shape({
  email: emailRequired
})

export const defaultAutocompleteValues: AutoCompleteSchemaType = {
  value: '',
  autoCompleteValue: ''
}
