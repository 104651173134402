import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  CardHeader,
  CardContent,
  MenuItem,
  Divider,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Payment as PaymentIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { MuiCard, MuiTextField, PaymentsList } from 'components'
import { useGetBasePaymentsQuery, Order_By } from 'api/generated'
import { formatCurrency } from 'utils/currency'
import {
  getPaymentsAggregateWhere,
  ALL,
  getPaymentsFilterPath
} from 'utils/chart'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'

export const TODAY_DATE_RANGE: DateRange<Date> = [new Date(), new Date()]

const RecentPaymentsList: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [locationId, setLocationId] = useState(ALL)
  const [userId, setUserId] = useState(ALL)
  const { data, loading, error, refetch } = useGetBasePaymentsQuery({
    variables: {
      where: getPaymentsAggregateWhere(TODAY_DATE_RANGE, userId, locationId),
      order_by: { created_at: Order_By.Desc }
    }
  })
  const dictionariesData = dictionariesQuery?.data
  const aggregate = data?.paymentsAggregate?.aggregate
  const aggregateSum = aggregate?.sum
  const totalCount = aggregate?.totalCount

  const handleChangeLocationId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocationId(ev.target.value)
    },
    []
  )

  const handleChangeUserId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUserId(ev.target.value)
    },
    []
  )

  const handleShowPaymentsInDetailTable = useCallback(() => {
    const selectedUser = dictionariesData?.users?.find(
      user => user?.id === userId
    )
    const selectedLocation = dictionariesData?.locations?.find(
      location => location?.id === locationId
    )
    const paymentsFilterPath = getPaymentsFilterPath(
      TODAY_DATE_RANGE,
      selectedUser?.full_name,
      selectedLocation?.name
    )

    navigate(paymentsFilterPath)
  }, [dictionariesData, userId, locationId, navigate])

  return (
    <MuiCard>
      <CardHeader
        title={t('recentPayments')}
        subheader={
          <Stack direction='row' spacing={1}>
            <Tooltip title={t('totalPayments')}>
              <Typography variant='body1'>{totalCount || 0}</Typography>
            </Tooltip>
            <Divider orientation='vertical' flexItem />
            <Tooltip title={t('totalAmount')}>
              <Typography variant='body1'>
                {aggregateSum
                  ? formatCurrency({
                      amount:
                        (aggregateSum?.amount || 0) +
                        (aggregateSum?.penalty_amount || 0)
                    })
                  : ''}
              </Typography>
            </Tooltip>
          </Stack>
        }
        action={
          <Tooltip title={t('showInDetail')}>
            <IconButton onClick={handleShowPaymentsInDetailTable}>
              <PaymentIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={2} direction='row'>
            <MuiTextField
              select
              label={t('user')}
              size='small'
              onChange={handleChangeUserId}
              value={userId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.users?.map(user => {
                return (
                  <MenuItem
                    key={user?.id}
                    value={user?.id}
                  >{`${user?.full_name}`}</MenuItem>
                )
              })}
            </MuiTextField>

            <MuiTextField
              select
              label={t('location')}
              size='small'
              onChange={handleChangeLocationId}
              value={locationId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.locations?.map(location => {
                return (
                  <MenuItem key={location?.id} value={location?.id}>
                    {location?.name}
                  </MenuItem>
                )
              })}
            </MuiTextField>
          </Stack>

          <PaymentsList
            payments={data?.payments}
            error={error}
            loading={loading}
            refetch={refetch}
          />
        </Stack>
      </CardContent>
    </MuiCard>
  )
}

export default RecentPaymentsList
