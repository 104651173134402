import React, { useState, useEffect } from 'react'
import {
  Box,
  InputLabel,
  FormHelperText,
  Typography,
  useTheme,
  Skeleton,
  BoxProps
} from '@mui/material'
import {
  AddAPhoto as AddAPhotoIcon,
  HighlightOff as HighlightOffIcon
} from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone'
import { DefaultTFuncReturn } from 'i18next'

import MuiButton from '../MuiButton'
import MuiCard from '../MuiCard'
import Image from '../Image'
import { firebaseUtils } from 'utils'

type FileUploadPreviewProps = {
  onChange(
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ): void
  filePath: string
  loading: boolean
  onDelete?(): void
  title?: string | DefaultTFuncReturn
  error?: string | DefaultTFuncReturn
}

const FileUploadPreview: React.FC<FileUploadPreviewProps> = ({
  onChange,
  filePath,
  loading,
  onDelete,
  title,
  error
}: FileUploadPreviewProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [urlFile, setUrlFile] = useState<string | undefined>()
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop: onChange,
    accept: { 'image/*': [] }
  })

  useEffect(() => {
    const getUrlFile = async () => {
      try {
        if (filePath) {
          const urlFile = await firebaseUtils.getUrlFile(filePath)

          setUrlFile(urlFile)
        } else {
          setUrlFile(undefined)
        }
      } catch (error) {
        setUrlFile(undefined)
      }
    }

    getUrlFile()
  }, [filePath])

  return (
    <>
      {title ? (
        <Box mb={2}>
          <InputLabel>{title}</InputLabel>
        </Box>
      ) : null}

      <MuiCard>
        <Box
          {...(getRootProps({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
            border: error ? 1 : undefined,
            borderColor: error ? theme.palette.error.main : undefined,
            height: 400
          }) as BoxProps)}
        >
          <input {...getInputProps()} />

          <Box display='flex' width='100%' justifyContent='space-between'>
            <MuiButton
              onClick={open}
              startIcon={<AddAPhotoIcon />}
              fullWidth={false}
            >
              {t('upload')}
            </MuiButton>

            <MuiButton
              onClick={onDelete}
              startIcon={<HighlightOffIcon />}
              fullWidth={false}
              disabled={!urlFile}
            >
              {t('delete')}
            </MuiButton>
          </Box>

          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'
            height='80%'
            width='100%'
          >
            {!urlFile && !loading && (
              <Typography component='div'>
                <Box mb={2}>{t('dragYourFileHere')}</Box>
              </Typography>
            )}

            {!urlFile && loading && <Skeleton height='100%' width='100%' />}

            {urlFile && !loading && (
              <Image src={urlFile} alt={filePath} width='100%' height={280} />
            )}
          </Box>
        </Box>
      </MuiCard>

      {error ? (
        <FormHelperText error={true} variant='outlined' margin={undefined}>
          {error}
        </FormHelperText>
      ) : null}
    </>
  )
}

export default FileUploadPreview
