import React from 'react'
import { Stack, Box, Paper, Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'

import MuiDataGrid from '../MuiDataGrid'
import RemoteImage from '../RemoteImage'
import {
  ClientFragmentFragment,
  Phone_Number_Type_Enum,
  Address_Type_Enum,
  Client_File_Type_Enum
} from 'api/generated'

type ClientDetailPanelProps = {
  data: ClientFragmentFragment
}

const ClientDetailPanel: React.FC<ClientDetailPanelProps> = ({
  data
}: ClientDetailPanelProps) => {
  const { t } = useTranslation()

  return (
    <Box p={2} height='100%'>
      <Paper sx={{ height: '100%' }}>
        <Stack
          direction='row'
          spacing={2}
          p={2}
          height='100%'
          divider={<Divider orientation='vertical' flexItem />}
        >
          <Stack spacing={2} flex={1}>
            <Typography variant='h6'>{t('phoneNumbers')}</Typography>
            <MuiDataGrid
              rows={data?.phone_numbers || []}
              Toolbar={null}
              height='100%'
              disableColumnMenu={true}
              hideFooter={true}
              density='compact'
              initialState={{ pinnedColumns: { right: ['actions'] } }}
              columns={[
                {
                  field: 'value',
                  headerName: `${t('phoneNumber')}`,
                  flex: 1
                },
                {
                  field: 'type',
                  headerName: `${t('type')}`,
                  flex: 1,
                  renderCell: params => t(params?.row?.type),
                  type: 'singleSelect',
                  valueOptions: Object.values(Phone_Number_Type_Enum)?.map(
                    type => ({
                      value: type,
                      label: t(type)
                    })
                  )
                }
              ]}
            />
          </Stack>

          <Stack spacing={2} flex={1}>
            <Typography variant='h6'>{t('addresses')}</Typography>
            <MuiDataGrid
              rows={data?.addresses || []}
              Toolbar={null}
              height='100%'
              disableColumnMenu={true}
              hideFooter={true}
              density='compact'
              columns={[
                {
                  field: 'value',
                  headerName: `${t('address')}`,
                  flex: 1
                },
                {
                  field: 'type',
                  headerName: `${t('type')}`,
                  flex: 1,
                  renderCell: params => t(params?.row?.type),
                  type: 'singleSelect',
                  valueOptions: Object.values(Address_Type_Enum)?.map(type => ({
                    value: type,
                    label: t(type)
                  }))
                },
                {
                  field: 'description',
                  headerName: `${t('description')}`,
                  flex: 1
                }
              ]}
            />
          </Stack>

          <Stack spacing={2} flex={1}>
            <Typography variant='h6'>{t('photos')}</Typography>
            <MuiDataGrid
              rows={data?.files || []}
              Toolbar={null}
              height='100%'
              disableColumnMenu={true}
              hideFooter={true}
              density='compact'
              columns={[
                {
                  field: 'value',
                  headerName: `${t('photo')}`,
                  flex: 1,
                  renderCell: params => {
                    const value = params?.value

                    return (
                      <RemoteImage
                        filePath={value}
                        height={100}
                        width={100}
                        style={{ objectFit: 'scale-down' }}
                      />
                    )
                  }
                },
                {
                  field: 'type',
                  headerName: `${t('type')}`,
                  flex: 1,
                  renderCell: params => t(params?.row?.type),
                  type: 'singleSelect',
                  valueOptions: Object.values(Client_File_Type_Enum)?.map(
                    type => ({
                      value: type,
                      label: t(type)
                    })
                  )
                },
                {
                  field: 'description',
                  headerName: `${t('description')}`,
                  flex: 1
                }
              ]}
            />
          </Stack>
        </Stack>
      </Paper>
    </Box>
  )
}

export default ClientDetailPanel
