import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  CardHeader,
  CardContent,
  MenuItem,
  Divider,
  Skeleton,
  IconButton,
  Tooltip,
  Typography,
  Box,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Payment as PaymentIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import {
  MuiCard,
  MuiDateRangePicker,
  MuiTextField,
  QueryError,
  PaymentsList
} from 'components'
import { Order_By, useGetPaymentsAggregateQuery } from 'api/generated'
import { formatCurrency } from 'utils/currency'
import {
  getPaymentsAggregateWhere,
  ALL,
  getPaymentsFilterPath
} from 'utils/chart'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'

const PaymentsChart: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const [locationId, setLocationId] = useState(ALL)
  const [userId, setUserId] = useState(ALL)
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date()
  ])
  const { data, loading, error, refetch } = useGetPaymentsAggregateQuery({
    variables: {
      where: getPaymentsAggregateWhere(dateRange, userId, locationId),
      order_by: { created_at: Order_By.Desc }
    }
  })
  const dictionariesData = dictionariesQuery?.data
  const aggregate = data?.paymentsAggregate?.aggregate
  const aggregateSum = aggregate?.sum
  const totalCount = aggregate?.totalCount
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChangeLocationId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocationId(ev.target.value)
    },
    []
  )

  const handleChangeUserId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUserId(ev.target.value)
    },
    []
  )

  const handleChangeDateRange = useCallback((newDateRange: DateRange<Date>) => {
    setDateRange(newDateRange)
  }, [])

  const handleShowPaymentsInDetailTable = useCallback(() => {
    const selectedUser = dictionariesData?.users?.find(
      user => user?.id === userId
    )
    const selectedLocation = dictionariesData?.locations?.find(
      location => location?.id === locationId
    )
    const paymentsFilterPath = getPaymentsFilterPath(
      dateRange,
      selectedUser?.full_name,
      selectedLocation?.name
    )

    navigate(paymentsFilterPath)
  }, [dateRange, dictionariesData, userId, locationId, navigate])

  const handleRefetchQuery = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <MuiCard>
      <CardHeader
        title={t('payments')}
        subheader={
          <Stack
            direction='row'
            spacing={1}
            divider={<Divider orientation='vertical' flexItem />}
          >
            <Tooltip title={t('totalPayments')}>
              <Typography variant='body1'>{totalCount || 0}</Typography>
            </Tooltip>

            <Tooltip title={t('totalAmount')}>
              <Typography variant='body1'>
                {aggregateSum
                  ? formatCurrency({
                      amount:
                        (aggregateSum?.amount || 0) +
                        (aggregateSum?.penalty_amount || 0)
                    })
                  : ''}
              </Typography>
            </Tooltip>
          </Stack>
        }
        action={
          <Tooltip title={t('showInDetail')}>
            <IconButton onClick={handleShowPaymentsInDetailTable}>
              <PaymentIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={2} direction='row'>
            <MuiTextField
              select
              label={t('user')}
              size='small'
              onChange={handleChangeUserId}
              value={userId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.users?.map(user => {
                return (
                  <MenuItem
                    key={user?.id}
                    value={user?.id}
                  >{`${user?.full_name}`}</MenuItem>
                )
              })}
            </MuiTextField>

            <MuiTextField
              select
              label={t('location')}
              size='small'
              onChange={handleChangeLocationId}
              value={locationId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.locations?.map(location => {
                return (
                  <MenuItem key={location?.id} value={location?.id}>
                    {location?.name}
                  </MenuItem>
                )
              })}
            </MuiTextField>
          </Stack>

          <MuiDateRangePicker
            value={dateRange}
            onChange={handleChangeDateRange}
          />

          {error ? (
            <QueryError
              error={error}
              loading={loading}
              refetch={handleRefetchQuery}
            />
          ) : loading && !data ? (
            <Stack
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              divider={
                <Divider
                  orientation={smDown ? 'horizontal' : 'vertical'}
                  flexItem
                />
              }
            >
              <Skeleton
                variant='rectangular'
                width='100%'
                sx={{ minWidth: 320 }}
                height={300}
              />
              <Skeleton
                variant='rectangular'
                width='100%'
                sx={{ minWidth: 320 }}
                height={300}
              />
            </Stack>
          ) : (
            <Stack
              spacing={2}
              direction={{ xs: 'column', sm: 'row' }}
              divider={
                <Divider
                  orientation={smDown ? 'horizontal' : 'vertical'}
                  flexItem
                />
              }
            >
              <Box flex={1}>
                <PieChart
                  series={[
                    {
                      valueFormatter: value =>
                        formatCurrency({ amount: value?.value }),
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      data: [
                        {
                          id: 0,
                          value: aggregateSum?.capital_amount || 0,
                          label: `${t('capital')}`
                        },
                        {
                          id: 1,
                          value: aggregateSum?.commission_amount || 0,
                          label: `${t('commission')}`
                        },
                        {
                          id: 2,
                          value: aggregateSum?.interest_amount || 0,
                          label: `${t('interest')}`
                        },
                        {
                          id: 3,
                          value:
                            aggregateSum?.commission_interest_arrears_amount ||
                            0,
                          label: `${t('commission_arrears')}`
                        },
                        {
                          id: 4,
                          value: aggregateSum?.interest_arrears_amount || 0,
                          label: `${t('interest_arrears')}`
                        },
                        {
                          id: 5,
                          value: aggregateSum?.penalty_amount || 0,
                          label: `${t('penalty')}`
                        }
                      ]
                    }
                  ]}
                  height={300}
                  margin={{ top: 10, bottom: 100, left: 10, right: 10 }}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: 0
                    }
                  }}
                />
              </Box>

              <Box flex={1}>
                <PaymentsList payments={data?.payments} />
              </Box>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </MuiCard>
  )
}

export default PaymentsChart
