import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { useAuth } from './auth'
import { ProviderProps, UserContextProps } from './types'
import { User_Role_Enum, User_Status_Enum } from 'api/generated'
import { authUtils } from 'utils'

const UserContext = createContext<UserContextProps | undefined>(undefined)

export const BASE_ADMIN_USERS = [
  User_Role_Enum.TenantAdmin,
  User_Role_Enum.LocationAdmin,
  User_Role_Enum.SuperAdmin
]

const UserProvider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const { firebaseUser, dbUser } = useAuth()
  const [showAdminUI, setShowAdminUI] = useState<undefined | boolean>(undefined)
  const userRole = dbUser?.role
  const allowedRoles = authUtils.getAllowedRolesByUserRole(userRole)
  const minorRoles = authUtils.getMinorRolesByUserRole(userRole)
  const isBlocked = dbUser?.status === User_Status_Enum.Blocked

  const isAdminUser = !!(dbUser && BASE_ADMIN_USERS?.includes(dbUser?.role))
  const isAnonymousUser = !!(
    dbUser && dbUser?.role === User_Role_Enum.Anonymous
  )
  const isAllowedToUpsertLoans = !!(
    dbUser && BASE_ADMIN_USERS?.includes(dbUser?.role)
  )
  const isAllowedToUpdateLoans = !!(
    dbUser && BASE_ADMIN_USERS?.includes(dbUser?.role)
  )
  const isAllowedToInsertLoans = !!(
    dbUser &&
    [...BASE_ADMIN_USERS, User_Role_Enum.Supervisor]?.includes(dbUser?.role)
  )
  const isAllowedToUpsertClients = !!(
    dbUser &&
    [
      ...BASE_ADMIN_USERS,
      User_Role_Enum.Supervisor,
      User_Role_Enum.Collector
    ]?.includes(dbUser?.role)
  )
  const isAllowedToUpsertUsers = !!(
    dbUser &&
    [...BASE_ADMIN_USERS, User_Role_Enum.Supervisor]?.includes(dbUser?.role)
  )

  const isAllowedToUpdatePayments = !!(
    dbUser && BASE_ADMIN_USERS?.includes(dbUser?.role)
  )

  const isAllowedToUpdateLocations = !!(
    dbUser && BASE_ADMIN_USERS?.includes(dbUser?.role)
  )

  const isAllowedToUpsertPaymentMethods = !!(
    dbUser &&
    [...BASE_ADMIN_USERS, User_Role_Enum.Supervisor]?.includes(dbUser?.role)
  )

  useEffect(() => {
    if (dbUser) {
      const isAdminUser = [
        ...BASE_ADMIN_USERS,
        User_Role_Enum.Supervisor
      ]?.includes(dbUser?.role)

      setShowAdminUI(isAdminUser)
    }
  }, [dbUser])

  const handleToggleLayoutType = useCallback(() => {
    setShowAdminUI(currentStatus => !currentStatus)
  }, [])

  const getImAvailableToUpdateUser = useCallback(
    (role?: User_Role_Enum): boolean => {
      if (!role) {
        return false
      }

      return minorRoles?.includes(role)
    },
    [minorRoles]
  )

  return (
    <UserContext.Provider
      value={{
        firebaseUser,
        dbUser,
        isAdminUser,
        isAnonymousUser,
        allowedRoles,
        showAdminUI,
        isAllowedToUpsertLoans,
        isAllowedToUpdateLoans,
        isAllowedToInsertLoans,
        isAllowedToUpsertClients,
        isAllowedToUpsertUsers,
        isAllowedToUpdatePayments,
        isAllowedToUpdateLocations,
        isAllowedToUpsertPaymentMethods,
        isBlocked,
        getImAvailableToUpdateUser,
        toggleLayoutType: handleToggleLayoutType
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const useUser = (): UserContextProps => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`)
  }

  return context
}

export { UserProvider, useUser }
