import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  CardHeader,
  CardContent,
  MenuItem,
  Divider,
  Tooltip,
  IconButton,
  Typography
} from '@mui/material'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Payments as PaymentsIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import {
  MuiCard,
  MuiDateRangePicker,
  MuiTextField,
  LoansList
} from 'components'
import { useGetBaseLoansQuery } from 'api/generated'
import { formatCurrency } from 'utils/currency'
import { getLoansAggregateWhere, ALL, getLoansFilterPath } from 'utils/chart'
import { DictionariesQueryProps } from 'screens/Authenticated/Dashboard/Admin'

const LoansChart: React.FC<DictionariesQueryProps> = ({
  dictionariesQuery
}: DictionariesQueryProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dictionariesData = dictionariesQuery?.data
  const [locationId, setLocationId] = useState(ALL)
  const [userId, setUserId] = useState(ALL)
  const [dateRange, setDateRange] = useState<DateRange<Date>>([
    new Date(),
    new Date()
  ])
  const { data, loading, error, refetch } = useGetBaseLoansQuery({
    variables: {
      where: getLoansAggregateWhere(dateRange, userId, locationId)
    }
  })

  const aggregate = data?.loansAggregate?.aggregate
  const aggregateSum = aggregate?.sum
  const totalCount = aggregate?.totalCount

  const handleChangeLocationId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setLocationId(ev.target.value)
    },
    []
  )

  const handleChangeUserId = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUserId(ev.target.value)
    },
    []
  )

  const handleChangeDateRange = useCallback((newDateRange: DateRange<Date>) => {
    setDateRange(newDateRange)
  }, [])

  const handleShowLoansInDetailTable = useCallback(() => {
    const selectedUser = dictionariesData?.users?.find(
      user => user?.id === userId
    )
    const selectedLocation = dictionariesData?.locations?.find(
      location => location?.id === locationId
    )
    const paymentsFilterPath = getLoansFilterPath(
      dateRange,
      selectedUser?.full_name,
      selectedLocation?.name
    )

    navigate(paymentsFilterPath)
  }, [dateRange, dictionariesData, userId, locationId, navigate])

  return (
    <MuiCard>
      <CardHeader
        title={t('loans')}
        subheader={
          <Stack
            direction='row'
            spacing={1}
            divider={<Divider orientation='vertical' flexItem />}
          >
            <Tooltip title={t('totalLoans')}>
              <Typography variant='body1'>{totalCount || 0}</Typography>
            </Tooltip>

            <Tooltip title={t('totalAmount')}>
              <Typography variant='body1'>
                {aggregateSum
                  ? formatCurrency({
                      amount: aggregateSum?.amount
                    })
                  : ''}
              </Typography>
            </Tooltip>
          </Stack>
        }
        action={
          <Tooltip title={t('showInDetail')}>
            <IconButton onClick={handleShowLoansInDetailTable}>
              <PaymentsIcon />
            </IconButton>
          </Tooltip>
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={2} direction='row'>
            <MuiTextField
              select
              label={t('user')}
              size='small'
              onChange={handleChangeUserId}
              value={userId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.users?.map(user => {
                return (
                  <MenuItem
                    key={user?.id}
                    value={user?.id}
                  >{`${user?.full_name}`}</MenuItem>
                )
              })}
            </MuiTextField>

            <MuiTextField
              select
              label={t('location')}
              size='small'
              onChange={handleChangeLocationId}
              value={locationId}
            >
              <MenuItem value={ALL}>{t(ALL)}</MenuItem>
              {dictionariesData?.locations?.map(location => {
                return (
                  <MenuItem key={location?.id} value={location?.id}>
                    {location?.name}
                  </MenuItem>
                )
              })}
            </MuiTextField>
          </Stack>

          <MuiDateRangePicker
            value={dateRange}
            onChange={handleChangeDateRange}
          />

          <LoansList
            loans={data?.loans}
            loading={loading}
            refetch={refetch}
            error={error}
          />
        </Stack>
      </CardContent>
    </MuiCard>
  )
}

export default LoansChart
