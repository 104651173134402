import React, { useMemo } from 'react'
import {
  DateRange,
  DateRangePicker,
  DateRangePickerProps,
  PickersShortcutsItem
} from '@mui/x-date-pickers-pro'
import { DefaultTFuncReturn, TFunction } from 'i18next'
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths
} from 'date-fns'
import { useTranslation } from 'react-i18next'

type MuiDateRangePickerProps = DateRangePickerProps<Date> & {
  helperText?: string | DefaultTFuncReturn
  error?: boolean
  fullWidth?: boolean
}

const getShortcutsItems = (
  t: TFunction
): PickersShortcutsItem<DateRange<Date>>[] => {
  const today = new Date()
  const lastWeek = subDays(today, 7)
  const lastMonth = subMonths(today, 1)

  return [
    {
      label: t('thisWeek'),
      getValue: () => [startOfWeek(today), endOfWeek(today)]
    },
    {
      label: t('lastWeek'),
      getValue: () => [startOfWeek(lastWeek), endOfWeek(lastWeek)]
    },
    {
      label: t('last7Days'),
      getValue: () => [lastWeek, today]
    },
    {
      label: t('currentMonth'),
      getValue: () => [startOfMonth(today), endOfMonth(today)]
    },
    {
      label: t('lastMonth'),
      getValue: () => [startOfMonth(lastMonth), endOfMonth(lastMonth)]
    },
    {
      label: t('today'),
      getValue: () => [startOfDay(today), endOfDay(today)]
    }
  ]
}

const MuiDateRangePicker: React.FC<MuiDateRangePickerProps> = ({
  helperText,
  error = false,
  fullWidth = true,
  ...props
}: MuiDateRangePickerProps) => {
  const { t } = useTranslation()
  const shortcutsItems = useMemo(() => getShortcutsItems(t), [t])

  return (
    <DateRangePicker
      slotProps={{
        shortcuts: {
          items: shortcutsItems
        },
        textField: {
          helperText,
          error,
          fullWidth,
          size: 'small'
        }
      }}
      {...props}
    />
  )
}

export default MuiDateRangePicker
