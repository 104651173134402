import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import {
  esES as esMuiCoreLocale,
  enUS as enMuiCoreLocale
} from '@mui/material/locale'
import {
  esES as esMuiPickerLocale,
  enUS as enMuiPickerLocale
} from '@mui/x-date-pickers-pro/locales'
import {
  esES as esMuiDataGridLocale,
  enUS as enMuiDataGridLocale
} from '@mui/x-data-grid-pro/locales'

const sharedPaletteThemeOptions: ThemeOptions['palette'] = {}

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    ...sharedPaletteThemeOptions,
    background: {
      default: '#111827',
      paper: '#111827'
    }
  }
}

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    ...sharedPaletteThemeOptions,
    background: {
      default: '#ffffff',
      paper: '#ffffff'
    }
  }
}

export default (type: 'light' | 'dark', locale = 'es'): Theme => {
  const coreLocale = locale === 'es' ? esMuiCoreLocale : enMuiCoreLocale
  const pickerLocale = locale === 'es' ? esMuiPickerLocale : enMuiPickerLocale
  const dataGridLocale =
    locale === 'es' ? esMuiDataGridLocale : enMuiDataGridLocale

  return type === 'dark'
    ? createTheme(darkThemeOptions, coreLocale, pickerLocale, dataGridLocale)
    : createTheme(lightThemeOptions, coreLocale, pickerLocale, dataGridLocale)
}
