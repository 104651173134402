import React from 'react'

import Header from './Header'
import Main from '../Shared/Main'
import Footer from './Footer'

type CollectorLayoutProps = {
  children: JSX.Element
}

const CollectorLayout: React.FC<CollectorLayoutProps> = ({
  children
}: CollectorLayoutProps) => {
  return (
    <>
      <Header />
      <Main paddingBottom={8} px={2} paddingTop={2}>
        {children}
      </Main>
      <Footer />
    </>
  )
}

export default CollectorLayout
