import { GridFilterModel, GridLogicOperator } from '@mui/x-data-grid-pro'
import { DateRange } from '@mui/x-date-pickers-pro'
import cloneDeep from 'lodash.clonedeep'

import {
  Payment_Bool_Exp,
  Loan_Bool_Exp,
  Client_Bool_Exp,
  GetChartsSummaryDataQueryVariables,
  User_Payment_Aggregation_Bool_Exp
} from 'api/generated'
import {
  endOfDay,
  format,
  startOfDay,
  startOfISOWeek,
  startOfMonth
} from 'date-fns'
import { generatePath } from 'utils/dataGrid'
import {
  LOAN_PAYMENTS_FILTERS_ENUM,
  LOAN_PAYMENT_FILTERS_OBJECT,
  TODAY_DATE_STRING_FORMAT
} from 'utils/loanFilters'
import {
  PAYMENTS_FILTERS_ENUM,
  PAYMENT_FILTERS_OBJECT
} from 'utils/paymentFilters'

export const ALL = 'ALL'

export const getPaymentsAggregateWhere = (
  dateRange: DateRange<Date>,
  userId: string,
  locationId: string
): Payment_Bool_Exp => {
  const where: Payment_Bool_Exp = {
    _and: [
      {
        deleted_at: {
          _is_null: true
        }
      },
      {
        created_at: {
          _gte: startOfDay(dateRange?.[0] || new Date())
        }
      },
      {
        created_at: {
          _lte: endOfDay(dateRange?.[1] || new Date())
        }
      }
    ]
  }

  if (userId && userId !== ALL) {
    where._and?.push({
      user_id: {
        _eq: userId
      }
    })
  }

  if (locationId && locationId !== ALL) {
    where._and?.push({
      location_id: {
        _eq: locationId
      }
    })
  }

  return where
}

export const getLoansAggregateWhere = (
  dateRange: DateRange<Date>,
  userId: string,
  locationId: string
): Loan_Bool_Exp => {
  const where: Loan_Bool_Exp = {
    _and: [
      {
        deleted_at: {
          _is_null: true
        }
      },
      {
        created_at: {
          _gte: startOfDay(dateRange?.[0] || new Date())
        }
      },
      {
        created_at: {
          _lte: endOfDay(dateRange?.[1] || new Date())
        }
      }
    ]
  }

  if (userId && userId !== ALL) {
    where._and?.push({
      user_id: {
        _eq: userId
      }
    })
  }

  if (locationId && locationId !== ALL) {
    where._and?.push({
      location_id: {
        _eq: locationId
      }
    })
  }

  return where
}

export const getPaymentsFilterPath = (
  dateRange: DateRange<Date>,
  user?: string | null,
  location?: string | null
) => {
  const filterModel: GridFilterModel = {
    items: [
      {
        id: 1,
        field: 'created_at',
        operator: 'onOrAfter',
        value: format(
          startOfDay(dateRange?.[0] || new Date()),
          "yyyy-MM-dd'T'HH:mm"
        )
      },
      {
        id: 2,
        field: 'created_at',
        operator: 'onOrBefore',
        value: format(
          endOfDay(dateRange?.[1] || new Date()),
          "yyyy-MM-dd'T'HH:mm"
        )
      }
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: []
  }

  if (user) {
    filterModel.items?.push({
      id: 3,
      field: 'user.full_name',
      operator: 'equals',
      value: user
    })
  }

  if (location) {
    filterModel.items?.push({
      id: 4,
      field: 'loan.location.name',
      operator: 'equals',
      value: location
    })
  }

  const path = generatePath({
    filterModel
  })

  return `/payments${path}`
}

export const getLoansFilterPath = (
  dateRange: DateRange<Date>,
  user?: string | null,
  location?: string | null
) => {
  const filterModel: GridFilterModel = {
    items: [
      {
        id: 1,
        field: 'created_at',
        operator: 'onOrAfter',
        value: format(
          startOfDay(dateRange?.[0] || new Date()),
          "yyyy-MM-dd'T'HH:mm"
        )
      },
      {
        id: 2,
        field: 'created_at',
        operator: 'onOrBefore',
        value: format(
          endOfDay(dateRange?.[1] || new Date()),
          "yyyy-MM-dd'T'HH:mm"
        )
      }
    ],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: []
  }

  if (user) {
    filterModel.items?.push({
      id: 3,
      field: 'user.full_name',
      operator: 'equals',
      value: user
    })
  }

  if (location) {
    filterModel.items?.push({
      id: 4,
      field: 'location.name',
      operator: 'equals',
      value: location
    })
  }

  const path = generatePath({
    filterModel
  })

  return `/loans${path}`
}

type SummaryChartBaseWhere = {
  _and: [
    | {
        deleted_at: {
          _is_null: boolean
        }
      }
    | {
        location_id: {
          _eq: string
        }
      }
  ]
}

type SummaryChartBasePaymentWhere = {
  _and: [
    | {
        deleted_at: {
          _is_null: boolean
        }
      }
    | {
        loan: {
          location_id: {
            _eq: string
          }
        }
      }
  ]
}

export const getSummaryAggregateWhere = (
  locationId: string
): GetChartsSummaryDataQueryVariables => {
  const baseWhere: SummaryChartBaseWhere = {
    _and: [
      {
        deleted_at: {
          _is_null: true
        }
      }
    ]
  }
  const basePaymentWhere: SummaryChartBasePaymentWhere = {
    _and: [
      {
        deleted_at: {
          _is_null: true
        }
      }
    ]
  }

  if (locationId && locationId !== ALL) {
    baseWhere['_and']?.push({
      location_id: {
        _eq: locationId
      }
    })

    basePaymentWhere['_and']?.push({
      loan: {
        location_id: {
          _eq: locationId
        }
      }
    })
  }

  const clientWhere: Client_Bool_Exp = cloneDeep(baseWhere)
  const loanWhere: Loan_Bool_Exp = cloneDeep(baseWhere)
  const upToDateLoanWhere: Loan_Bool_Exp = cloneDeep(baseWhere)
  const todayLoanWhere: Loan_Bool_Exp = cloneDeep(baseWhere)
  const lateLoanWhere: Loan_Bool_Exp = cloneDeep(baseWhere)
  const paymentWhere: Payment_Bool_Exp = cloneDeep(basePaymentWhere)
  const todayPaymentWhere: Payment_Bool_Exp = cloneDeep(basePaymentWhere)
  const weeklyPaymentWhere: Payment_Bool_Exp = cloneDeep(basePaymentWhere)
  const monthlyPaymentWhere: Payment_Bool_Exp = cloneDeep(basePaymentWhere)

  upToDateLoanWhere['_and']?.push({
    payment_date: {
      _gt: TODAY_DATE_STRING_FORMAT
    }
  })

  todayLoanWhere['_and']?.push({
    payment_date: {
      _eq: TODAY_DATE_STRING_FORMAT
    }
  })

  lateLoanWhere['_and']?.push({
    payment_date: {
      _lt: TODAY_DATE_STRING_FORMAT
    }
  })

  todayPaymentWhere['_and']?.push({
    created_at: {
      _gte: startOfDay(new Date())
    }
  })

  weeklyPaymentWhere['_and']?.push({
    created_at: {
      _gte: startOfISOWeek(new Date())
    }
  })

  monthlyPaymentWhere['_and']?.push({
    created_at: {
      _gte: startOfMonth(new Date())
    }
  })

  return {
    clientWhere,
    loanWhere,
    todayLoanWhere,
    upToDateLoanWhere,
    lateLoanWhere,
    paymentWhere,
    todayPaymentWhere,
    weeklyPaymentWhere,
    monthlyPaymentWhere
  }
}

export const getLoansSummaryFilterPath = (
  location?: string | null,
  loanPaymentFilter?: LOAN_PAYMENTS_FILTERS_ENUM
) => {
  const filterModel: GridFilterModel = {
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: []
  }

  if (location) {
    filterModel.items?.push({
      id: 1,
      field: 'location.name',
      operator: 'equals',
      value: location
    })
  }

  if (loanPaymentFilter) {
    const filter = LOAN_PAYMENT_FILTERS_OBJECT?.[loanPaymentFilter]
    const item = filter?.filter?.items?.[0]
    filterModel.items?.push(item)
  }

  const path = generatePath({
    filterModel
  })

  return `/loans${path}`
}

export const getPaymentsSummaryFilterPath = (
  location?: string | null,
  paymentFilter?: PAYMENTS_FILTERS_ENUM
) => {
  const filterModel: GridFilterModel = {
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: []
  }

  if (location) {
    filterModel.items?.push({
      id: 1,
      field: 'loan.location.name',
      operator: 'equals',
      value: location
    })
  }

  if (paymentFilter) {
    const filter = PAYMENT_FILTERS_OBJECT?.[paymentFilter]
    const item = filter?.filter?.items?.[0]
    filterModel.items?.push(item)
  }

  const path = generatePath({
    filterModel
  })

  return `/payments${path}`
}

export const getClientsSummaryFilterPath = (location?: string | null) => {
  const filterModel: GridFilterModel = {
    items: [],
    logicOperator: GridLogicOperator.And,
    quickFilterLogicOperator: GridLogicOperator.And,
    quickFilterValues: []
  }

  if (location) {
    filterModel.items?.push({
      id: 1,
      field: 'location.name',
      operator: 'equals',
      value: location
    })
  }

  const path = generatePath({
    filterModel
  })

  return `/clients${path}`
}

export const getUserPaymentAggregateWhere = (
  dateRange: DateRange<Date>,
  userId: string,
  locationId: string
): Payment_Bool_Exp => {
  const where: User_Payment_Aggregation_Bool_Exp = {
    _and: []
  }

  if (dateRange?.[0] && dateRange?.[1]) {
    where._and?.push(
      {
        payment_date: {
          _gte: format(dateRange?.[0], 'yyyy-MM-dd')
        }
      },
      {
        payment_date: {
          _lte: format(dateRange?.[1], 'yyyy-MM-dd')
        }
      }
    )
  }

  if (userId && userId !== ALL) {
    where._and?.push({
      user_id: {
        _eq: userId
      }
    })
  }

  if (locationId && locationId !== ALL) {
    where._and?.push({
      location_id: {
        _eq: locationId
      }
    })
  }

  return where
}

export default {
  getPaymentsAggregateWhere,
  getLoansAggregateWhere,
  getPaymentsFilterPath,
  getLoansFilterPath,
  getSummaryAggregateWhere,
  getLoansSummaryFilterPath,
  getPaymentsSummaryFilterPath,
  getClientsSummaryFilterPath,
  getUserPaymentAggregateWhere,
  ALL
}
