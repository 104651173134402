import { Loan_Status_Enum } from 'api/generated'
import {
  stringRequired,
  uuidRequired,
  stringNullable,
  object,
  AutoCompleteSchemaType,
  idAutoCompleteSchema,
  dateRequired,
  string,
  defaultAutocompleteValues
} from '../common'

export type UpsertLoanSchemaType = {
  id?: string
  amount: string
  balance?: string
  capital_percentage?: string
  commission_percentage?: string
  interest_percentage?: string
  interest_arrears_amount?: string
  periodicity: string
  type: string
  status: string
  currency: string
  client_id: AutoCompleteSchemaType
  location_id: string
  user_id: AutoCompleteSchemaType
  payment_date: Date
}

export type PayLoanSchemaType = {
  paymentType: string
  paymentAmount: string
  penaltyAmount: string
  paymentMethodId: string
}

export const upsertLoanSchema = object.shape({
  id: stringNullable,
  amount: stringRequired,
  balance: string,
  capital_percentage: stringNullable,
  commission_percentage: stringNullable,
  interest_percentage: stringNullable,
  interest_arrears_amount: stringNullable,
  periodicity: stringRequired,
  type: stringRequired,
  status: stringRequired,
  currency: stringRequired,
  client_id: idAutoCompleteSchema,
  location_id: uuidRequired,
  user_id: idAutoCompleteSchema,
  payment_date: dateRequired
})

export const payLoanSchema = object.shape({
  paymentType: stringRequired,
  paymentAmount: stringRequired,
  penaltyAmount: string,
  paymentMethodId: stringRequired
})

export const defaultLoanValues: UpsertLoanSchemaType = {
  id: '',
  amount: '',
  balance: '',
  capital_percentage: '',
  commission_percentage: '',
  interest_percentage: '',
  interest_arrears_amount: '',
  periodicity: '',
  type: '',
  status: Loan_Status_Enum.Active,
  currency: '',
  client_id: defaultAutocompleteValues,
  location_id: '',
  user_id: defaultAutocompleteValues,
  payment_date: new Date()
}

export const defaultPayLoanValues: PayLoanSchemaType = {
  paymentType: '',
  paymentAmount: '',
  penaltyAmount: '',
  paymentMethodId: ''
}
