import React, { useCallback, useMemo } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  Alert,
  AlertTitle
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { format, parseISO } from 'date-fns'
import { ApolloError } from '@apollo/client'

import MuiButton from '../MuiButton'
import { LoanFragmentFragment, Payment_Type_Enum } from 'api/generated'
import {
  payLoanSchema,
  PayLoanSchemaType,
  defaultPayLoanValues
} from 'schemas/loan'
import { MuiTextField, NumberField } from 'components'
import { formatCurrency, getCurrencyMetadata } from 'utils/currency'
import { getPaymentMetadata } from 'utils/payment'
import { toNumber } from 'utils/string'
import { useUser } from 'context/auth/user'

type PayLoanDialogProps = {
  loan?: LoanFragmentFragment
  openDialog: boolean
  onAccept(data: PayLoanSchemaType, callback?: () => void): void
  onCancel(): void
  loading: boolean
  error: ApolloError | undefined
}

const PayLoanDialog: React.FC<PayLoanDialogProps> = ({
  loan,
  openDialog,
  onAccept,
  onCancel,
  loading,
  error
}: PayLoanDialogProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isBlocked } = useUser()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { control, handleSubmit, watch, reset } = useForm<PayLoanSchemaType>({
    resolver: yupResolver(payLoanSchema),
    defaultValues: defaultPayLoanValues
  })
  const paymentAmount = toNumber(watch('paymentAmount'))
  const paymentType = watch('paymentType') as Payment_Type_Enum
  const currencyMetadata = getCurrencyMetadata(loan?.currency)
  const paymentMetadata = loan
    ? getPaymentMetadata(loan, paymentAmount, paymentType)
    : null

  const onSubmit = handleSubmit(data => {
    onAccept(data, () => reset(defaultPayLoanValues))
  })

  const handleOnClose = useCallback(() => {
    if (!loading) {
      reset(defaultPayLoanValues)
      onCancel()
    }
  }, [loading, onCancel, reset])

  const loanSummaryTableData = useMemo(
    () => [
      {
        title: 'type',
        value: t(`${loan?.type}`)
      },
      {
        title: 'periodicity',
        value: t(`${loan?.periodicity}`)
      },
      {
        title: 'payment_date',
        value: loan?.payment_date
          ? format(parseISO(loan?.payment_date), 'dd/MM/yyyy')
          : ''
      },
      {
        title: 'amount',
        value: formatCurrency({
          amount: loan?.amount,
          currency: loan?.currency
        })
      },
      {
        title: 'balance',
        value: formatCurrency({
          amount: loan?.balance,
          currency: loan?.currency
        })
      },
      {
        title: 'interest_percentage',
        value: `${loan?.interest_percentage}%`
      },
      {
        title: 'commission_percentage',
        value: `${loan?.commission_percentage}%`
      },
      {
        title: 'capital_percentage',
        value: `${loan?.capital_percentage}%`
      },
      {
        title: 'minimumPaymentPercentage',
        value: `${loan?.location?.minimum_payment_percentage || 0}%`
      },
      {
        title: 'interestArrearsAmount',
        value: formatCurrency({
          amount: loan?.interest_arrears_amount,
          currency: loan?.currency
        })
      },
      {
        title: 'commissionInterestArrearsAmount',
        value: formatCurrency({
          amount: loan?.commission_interest_arrears_amount,
          currency: loan?.currency
        })
      },
      {
        title: 'amountToFinish',
        value: paymentMetadata?.maximumPaymentAmount?.formattedValue
      },
      {
        title: 'minimumPaymentAmount',
        value: paymentMetadata?.minimumPaymentAmount?.formattedValue
      }
    ],
    [loan, t, paymentMetadata]
  )

  const summaryTableData = useMemo(
    () => [
      {
        title: 'paymentAmount',
        expected: paymentMetadata?.expectedPaymentAmount?.formattedValue,
        received: paymentMetadata?.paymentAmount?.formattedValue
      },
      {
        title: 'interest',
        expected: paymentMetadata?.expectedInterestAmount?.formattedValue,
        received: paymentMetadata?.interestAmount?.formattedValue
      },
      {
        title: 'commission',
        expected: paymentMetadata?.expectedCommissionAmount?.formattedValue,
        received: paymentMetadata?.commissionAmount?.formattedValue
      },
      {
        title: 'interestArrears',
        expected:
          paymentMetadata?.expectedInterestArrearsAmount?.formattedValue,
        received: paymentMetadata?.interestArrearsAmount?.formattedValue
      },
      {
        title: 'commissionInterestArrears',
        expected:
          paymentMetadata?.expectedCommissionInterestArrearsAmount
            ?.formattedValue,
        received:
          paymentMetadata?.commissionInterestArrearsAmount?.formattedValue
      },
      {
        title: 'capital',
        expected: paymentMetadata?.expectedCapitalAmount?.formattedValue,
        received: paymentMetadata?.capitalAmount?.formattedValue
      }
    ],
    [paymentMetadata]
  )

  return (
    <Dialog
      open={openDialog}
      onClose={handleOnClose}
      maxWidth='md'
      fullWidth
      fullScreen={fullScreen}
      scroll='paper'
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{`${loan?.client?.full_name} - ${formatCurrency({
          amount: loan?.amount,
          currency: loan?.currency
        })}`}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {error?.message ? (
              <Grid item xs={12}>
                <Alert severity='error'>
                  <AlertTitle>{t('error')}</AlertTitle>
                  {t(error?.message)}
                </Alert>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={5}>
              <Stack spacing={2} justifyContent='center'>
                <Typography variant='h6'>{t('payment')}</Typography>
                <Controller
                  name='paymentAmount'
                  control={control}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error }
                  }) => {
                    return (
                      <MuiTextField
                        label={t('amount')}
                        error={!!error?.message}
                        helperText={error?.message && t(error?.message)}
                        inputRef={ref}
                        InputProps={{
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          inputComponent: NumberField as any,
                          inputProps: {
                            prefix: `${currencyMetadata?.symbol || ''} `
                          }
                        }}
                        {...fieldProps}
                      />
                    )
                  }}
                />

                <Controller
                  name='paymentType'
                  control={control}
                  defaultValue=''
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error }
                  }) => {
                    return (
                      <MuiTextField
                        label={t('type')}
                        error={!!error?.message}
                        helperText={error?.message && t(error?.message)}
                        inputRef={ref}
                        select
                        {...fieldProps}
                      >
                        {Object.values(Payment_Type_Enum)?.map(value => {
                          return (
                            <MenuItem key={value} value={value}>
                              {t(value)}
                            </MenuItem>
                          )
                        })}
                      </MuiTextField>
                    )
                  }}
                />

                <Controller
                  name='paymentMethodId'
                  control={control}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error }
                  }) => {
                    const paymentMethods = loan?.location?.payment_methods || []

                    return (
                      <MuiTextField
                        label={t('paymentMethod')}
                        error={!!error?.message}
                        helperText={error?.message && t(error?.message)}
                        inputRef={ref}
                        select
                        {...fieldProps}
                      >
                        {paymentMethods?.map(paymentMethod => {
                          return (
                            <MenuItem
                              key={paymentMethod?.id}
                              value={paymentMethod?.id}
                            >
                              {paymentMethod?.name}
                            </MenuItem>
                          )
                        })}
                      </MuiTextField>
                    )
                  }}
                />
                <Controller
                  name='penaltyAmount'
                  control={control}
                  render={({
                    field: { ref, ...fieldProps },
                    fieldState: { error }
                  }) => {
                    return (
                      <MuiTextField
                        label={t('penaltyAmount')}
                        error={!!error?.message}
                        helperText={error?.message && t(error?.message)}
                        inputRef={ref}
                        InputProps={{
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          inputComponent: NumberField as any,
                          inputProps: {
                            prefix: `${currencyMetadata?.symbol || ''} `
                          }
                        }}
                        {...fieldProps}
                      />
                    )
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant='h6'>{t('paymentSummary')}</Typography>

              <TableContainer component={Paper}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell variant='head'>{`${t(
                        'description'
                      )}`}</TableCell>
                      <TableCell variant='head' align='right'>{`${t(
                        'expected'
                      )}`}</TableCell>
                      <TableCell variant='head' align='right'>{`${t(
                        'received'
                      )}`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {summaryTableData?.map(row => {
                      return (
                        <TableRow key={row?.title}>
                          <TableCell>{t(row?.title)}</TableCell>
                          <TableCell align='right'>{row?.expected}</TableCell>
                          <TableCell align='right'>{row?.received}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='h6'>{t('loan')}</Typography>
            <TableContainer component={Paper}>
              <Table size='small'>
                <TableBody>
                  {loanSummaryTableData?.map(row => {
                    return (
                      <TableRow key={row?.title}>
                        <TableCell>{t(row?.title)}</TableCell>
                        <TableCell align='right'>{row?.value}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={handleOnClose}
            color='secondary'
            disabled={loading}
            startIcon={<CloseIcon />}
          >
            {t('cancel')}
          </MuiButton>

          {!isBlocked ? (
            <MuiButton
              color='primary'
              disabled={loading}
              startIcon={<CheckIcon />}
              type='submit'
            >
              {t('confirm')}
            </MuiButton>
          ) : null}
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default PayLoanDialog
