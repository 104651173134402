import React from 'react'
import {
  CardHeader,
  CardContent,
  Typography,
  CardProps,
  CardHeaderProps,
  Tooltip,
  Skeleton
} from '@mui/material'
import { DefaultTFuncReturn } from 'i18next'

import MuiCard from '../MuiCard'

export type SummaryCardChartProps = {
  cardProps?: CardProps
  cardHeaderProps?: CardHeaderProps
  value: string | number
  valueTooltipTitle?: string | DefaultTFuncReturn
  loading?: boolean
}

const SummaryCardChart: React.FC<SummaryCardChartProps> = ({
  cardProps,
  cardHeaderProps,
  value,
  valueTooltipTitle,
  loading
}: SummaryCardChartProps) => {
  return loading && !value ? (
    <Skeleton variant='rectangular' height='100%' width='100%' />
  ) : (
    <MuiCard elevation={2} sx={{ flex: 1 }} {...cardProps}>
      <CardHeader
        titleTypographyProps={{ variant: 'body2' }}
        subheaderTypographyProps={{ variant: 'caption' }}
        {...cardHeaderProps}
      />
      <CardContent>
        <Tooltip title={valueTooltipTitle}>
          <Typography variant='h4' textAlign='center'>
            {value}
          </Typography>
        </Tooltip>
      </CardContent>
    </MuiCard>
  )
}

export default SummaryCardChart
