import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react'
import { useTranslation } from 'react-i18next'
import { es as esDateFnsLocale, enUS as enDateFnsLocale } from 'date-fns/locale'

import { constants } from 'config'
import {
  ThemeTypeProviderProps,
  ThemeType,
  ThemeTypeContextProps
} from './types'

const {
  LOCAL_STORAGE_KEYS: { THEME_TYPE }
} = constants

const ThemeTypeContext = createContext<ThemeTypeContextProps | undefined>(
  undefined
)

const ThemeTypeProvider: React.FC<ThemeTypeProviderProps> = ({
  children
}: ThemeTypeProviderProps) => {
  const [themeType, setThemeType] = useState<ThemeType>(
    (window.localStorage.getItem(THEME_TYPE) as ThemeType) || 'dark'
  )
  const {
    i18n: { language }
  } = useTranslation()
  const dateFnsLocale = language === 'es' ? esDateFnsLocale : enDateFnsLocale

  useEffect(() => {
    if (themeType) {
      window.localStorage.setItem(THEME_TYPE, themeType)
    }
  }, [themeType])

  useEffect(() => {
    const persistType = window.localStorage.getItem(THEME_TYPE) as ThemeType

    if (persistType) {
      setThemeType(persistType)
    }
  }, [])

  const handleSetThemeType = useCallback((themeType: ThemeType): void => {
    setThemeType(themeType)
  }, [])

  return (
    <ThemeTypeContext.Provider
      value={{
        themeType,
        setThemeType: handleSetThemeType,
        isDarkTheme: themeType === 'dark',
        isLightTheme: themeType === 'light',
        dateFnsLocale
      }}
    >
      {children}
    </ThemeTypeContext.Provider>
  )
}

const useThemeType = (): ThemeTypeContextProps => {
  const context = useContext(ThemeTypeContext)

  if (context === undefined) {
    throw new Error(`useThemeType must be used within a ThemeTypeProvider`)
  }

  return context
}

export { ThemeTypeProvider, useThemeType }
